<template>
    <RouterView v-slot="{ Component, route }">
        <template v-if="route.meta.fullscreen">
            <component :is="Component" />
        </template>
        <template v-else>
            <div class="fixed top-0 z-40 flex w-full flex-col bg-white shadow">
                <NavLayoutHelper>
                    <TopHeader />
                    <template #meta>
                        <div class="flex h-full items-center justify-end gap-4">
                            <SlaaskButton />
                            <MuvalButton
                                alternate
                                quaternary
                                type="button"
                                icon="system-phone"
                                href="tel:1300168825"
                                class="noSelect hidden justify-between gap-4 rounded-full !text-muval-gray-1 lg:flex"
                                @click="analytics.phoneClicked()"
                            >
                                1300 168 825
                            </MuvalButton>
                        </div>
                    </template>
                </NavLayoutHelper>
                <Progress />
            </div>

            <div class="z-0 mx-auto bg-app font-body">
                <div
                    class="mx-auto mb-32 mt-4 pt-14 sm:mb-32 md:mt-10"
                    :class="!app.ready ? 'skeleton' : ''"
                >
                    <component
                        :is="Component"
                        v-show="!session.loading"
                    />
                    <div v-show="session.loading">
                        <div class="flex h-full w-screen">
                            <div class="m-auto flex flex-col items-center gap-4">
                                <MuvalLoader class="h-64 w-64" />
                                <span>Fetching your booking...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                v-if="session.hasSession && session.isReady"
                class="z-50"
            />
            <MoveOverlay class="z-50" />
            <ToastNotifications class="z-50" />
        </template>
    </RouterView>
    <BreakpointDisplay />
</template>

<script setup>
import TopHeader from '@/components/TopHeader.vue';
import MoveOverlay from './components/MoveOverlay.vue';
import MuvalLoader from '@/components/MuvalLoader.vue';
import BreakpointDisplay from '@/components/BreakpointDisplay.vue';

import ToastNotifications from './components/ToastNotifications.vue';
import NavLayoutHelper from './components/NavLayoutHelper.vue';
import Progress from './components/utilities/Progress.vue';
import 'tippy.js/animations/scale.css';

// Stores
import { useSessionStore, useAppStore, useAnalyticsStore } from '@/store';

const app = useAppStore();
const session = useSessionStore();
const analytics = useAnalyticsStore();
analytics.addGoogleTagManager();
</script>

<style>
.noSelect {
    touch-action: manipulation;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.slaask-button {
    display: none;
}
</style>
