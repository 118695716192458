<template>
    <div class="flex flex-col gap-4">
        <div class="flex gap-4">
            <div class="h-[93px] w-[93px] shrink-0 items-start rounded-md">
                <SvgIcon
                    class="h-full w-full"
                    name="selfy-match-icon"
                />
            </div>

            <div class="flex max-w-[500px] flex-col items-start justify-between gap-1">
                <div class="flex items-center gap-2">
                    <span class="font-medium leading-none"> Selfy Self-Pack Container </span>
                </div>

                <span class="text-left text-xs">
                    This option requires you to load and unload the container yourself. For full removalist service,
                    speak to a Muval team member.
                </span>

                <div class="flex flex-wrap gap-2">
                    <a
                        href="https://www.muval.com.au/media/docs/selfy/Selfy_HowItWorks.pdf"
                        target="_blank"
                        class="inline-flex items-center gap-1 whitespace-nowrap rounded bg-selfy-lime-2 p-1 text-selfy-dark"
                        @click.prevent.stop="openDocument"
                    >
                        <div class="flex h-3.5 w-3.5 items-center justify-center">
                            <SvgIcon
                                name="system-download"
                                class="h-3.5 w-3.5"
                            />
                        </div>
                        <span class="-ml-0.5 mr-0.5 text-xs font-medium underline"> How it works </span>
                    </a>
                    <a
                        href="https://www.muval.com.au/media/docs/selfy/Selfy_FAQs.pdf"
                        target="_blank"
                        class="inline-flex items-center gap-1 whitespace-nowrap rounded bg-selfy-lime-2 p-1 text-selfy-dark"
                        @click.prevent.stop="openDocument"
                    >
                        <div class="flex h-3.5 w-3.5 items-center justify-center">
                            <SvgIcon
                                name="system-download"
                                class="h-3.5 w-3.5"
                            />
                        </div>
                        <span class="-ml-0.5 mr-0.5 text-xs font-medium underline"> FAQs </span>
                    </a>
                    <a
                        href="https://www.muval.com.au/media/docs/selfy/Selfy_TipsForPacking.pdf"
                        target="_blank"
                        class="inline-flex items-center gap-1 whitespace-nowrap rounded bg-selfy-lime-2 p-1 text-selfy-dark"
                        @click.prevent.stop="openDocument"
                    >
                        <div class="flex h-3.5 w-3.5 items-center justify-center">
                            <SvgIcon
                                name="system-download"
                                class="h-3.5 w-3.5"
                            />
                        </div>
                        <span class="-ml-0.5 mr-0.5 text-xs font-medium underline"> Packing tips </span>
                    </a>
                    <a
                        href="https://www.muval.com.au/media/docs/selfy/Selfy_ContainerRequirements.pdf"
                        target="_blank"
                        class="inline-flex items-center gap-1 whitespace-nowrap rounded bg-selfy-lime-2 p-1 text-selfy-dark"
                        @click.prevent.stop="openDocument"
                    >
                        <div class="flex h-3.5 w-3.5 items-center justify-center">
                            <SvgIcon
                                name="system-download"
                                class="h-3.5 w-3.5"
                            />
                        </div>
                        <span class="-ml-0.5 mr-0.5 text-xs font-medium underline"> Container requirements </span>
                    </a>
                    <a
                        href="https://www.muval.com.au/media/docs/SelfySelfPackContainers_TermsAndConditions.pdf"
                        target="_blank"
                        class="inline-flex items-center gap-1 whitespace-nowrap rounded bg-selfy-lime-2 p-1 text-selfy-dark"
                        @click.prevent.stop="openDocument"
                    >
                        <div class="flex h-3.5 w-3.5 items-center justify-center">
                            <SvgIcon
                                name="system-download"
                                class="h-3.5 w-3.5"
                            />
                        </div>
                        <span class="-ml-0.5 mr-0.5 text-xs font-medium underline"> T&Cs </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { MoveMatch } from '@/types';

interface Props {
    match: MoveMatch;
}

defineProps<Props>();

const openDocument = (event: any) => {
    window.open(event.currentTarget.href, '_blank');
};
</script>
