import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { useSessionStore } from '@/store/session';
import { refDebounced } from '@vueuse/core';

export const useInventoryStore = defineStore('inventory', () => {
    const sessionStore = useSessionStore();

    // hack to get around push not working from custom item form
    const push = ref(null);

    const CUSTOM_CATEGORY_ID = 1;
    const CATEGORY_URL = '/customers/book/inventory-categories';
    const OPTION_URL = '/customers/book/inventory-options';

    const CREATE_CUSTOM_OPTION_URL = computed(() => {
        return `/customers/book/orders/${sessionStore.getOrderId}/inventory/custom`;
    });

    const GET_CUSTOM_OPTIONS_URL = computed(() => {
        return `/customers/book/orders/${sessionStore.getOrderId}/inventory/custom`;
    });

    const loading = ref(true);

    const searchTerm = ref('');
    const searchTermDebounced = refDebounced(searchTerm, 200);

    const categories = ref([]);
    const serverCategories = ref([]);
    const selectedItems = ref([]);
    const filterCategory = ref(2);
    const inventoryOptions = ref([]);

    function toggleCustomItemForm() {
        searchTerm.value = '';
        searched.value = [];
        filterCategory.value = CUSTOM_CATEGORY_ID;
    }

    watch(searchTermDebounced, async () => {
        await search();
    });

    // Initialize inventory with selectedItems
    async function init() {
        loading.value = true;

        await fetchCategories();
        await fetchCustomOptions();
        await retrieve();

        loading.value = false;
    }

    async function retrieve() {
        const response = await window.axios.get(`/customers/book/orders/${sessionStore.getOrderId}/inventory`);

        let remapped = response.data.data.map((item) => {
            return {
                inventory_option: {
                    id: item.inventory_option.id,
                    ...item.inventory_option,
                },
                inventory_option_id: item.inventory_option.id,
                qty: item.qty,
                name: item.inventory_option.name,
            };
        });

        selectedItems.value = remapped;
    }

    async function update(inventory) {
        const filteredInventory = inventory.filter((item) => {
            return item.qty > 0;
        });

        const response = await window.axios.put(`/customers/book/orders/${sessionStore.getOrderId}/inventory`, {
            move: {
                inventory: filteredInventory,
            },
        });

        console.log('Store@Inventory update response', response.data.inventory);

        selectedItems.value = response.data.inventory.map((item) => {
            return {
                inventory_option: {
                    id: item.inventory_option.id,
                    ...item.inventory_option,
                },
                inventory_option_id: item.inventory_option.id,
                qty: item.qty,
                name: item.inventory_option.name,
            };
        });
    }

    async function fetchCategories() {
        try {
            loading.value = true;

            const response = await window.axios.get(`${CATEGORY_URL}`, {
                params: {
                    type: 'MOVE',
                },
            });

            const formatted = response.data.data;

            categories.value = formatted;

            serverCategories.value = cloneDeep(response.data.data);

            loading.value = false;
        } catch (error) {
            console.log('Store@Inventory Fetch categories error', error);
            throw error;
        }
    }

    const isFetchingSearch = ref(false);
    const searched = ref([]);

    async function search() {
        try {
            loading.value = true;

            isFetchingSearch.value = true;

            const response = await window.axios.get(`${OPTION_URL}`, {
                params: {
                    type: 'MOVE',
                    search: searchTerm.value,
                },
            });

            const options = response.data.data;

            searched.value = options;

            loading.value = false;
        } catch (error) {
            console.log('Store@Inventory Fetch options error', error);
            throw error;
        } finally {
            isFetchingSearch.value = false;
        }
    }

    async function fetchCustomOptions() {
        try {
            loading.value = true;

            const customOptions = await retrieveCustomOptionItems();

            categories.value = categories.value.map((category) => {
                if (category.id === CUSTOM_CATEGORY_ID) {
                    category.move_options = customOptions;
                }

                return {
                    ...category,
                    isVisible: false,
                };
            });
        } catch (error) {
            console.log('Store@Inventory Fetch options error', error);

            throw error;
        } finally {
            loading.value = false;
        }
    }

    function addCustomOption(option) {
        categories.value = categories.value.map((category) => {
            if (category.id === CUSTOM_CATEGORY_ID) {
                category.move_options.push(option);
            }

            return category;
        });
    }

    /*
     * Creates a custom option for the current customer and adds it to the selectedItems array
     */
    async function createCustomOptionItem(payload) {
        const { data } = await window.axios.post(CREATE_CUSTOM_OPTION_URL.value, payload);

        addCustomOption(data.custom_option, payload.qty);

        return data.custom_option;
    }

    /*
     * Retrieves all custom options for the current customer
     */
    async function retrieveCustomOptionItems() {
        const { data } = await window.axios.get(GET_CUSTOM_OPTIONS_URL.value);

        return data.data;
    }

    function setCategoryFilter(categoryId, clearSearchTerm = true) {
        if (categoryId === filterCategory.value && categoryId !== CUSTOM_CATEGORY_ID) {
            filterCategory.value = null;
        } else {
            if (clearSearchTerm) {
                searchTerm.value = '';
            }

            filterCategory.value = categoryId;
        }
    }

    function showPotPlantAlert(items) {
        if (
            !sessionStore.getPrimaryProduct.pickup_state ||
            !sessionStore.getPrimaryProduct.delivery_state ||
            sessionStore.getPrimaryProduct.pickup_state == sessionStore.getPrimaryProduct.delivery_state
        ) {
            return false;
        }

        const potPlants = categories.value.flatMap((category) =>
            category.move_options.filter((option) => option.name.includes('Pot Plant')).map((option) => option.id),
        );

        const showAlert = items.some((item) => potPlants.includes(item.inventory_option_id) && item.qty > 0);

        return showAlert;
    }

    /*
     *  Computed Getters
     */

    const getCategories = computed(() => {
        if (categories.value && categories.value.length) {
            return categories.value;
        } else {
            return [];
        }
    });

    const getOptions = computed(() => {
        return getCategories.value
            .filter((category) => category.id === filterCategory.value)
            .flatMap((category) => category.move_options || []);
    });

    const getCalculatedSpace = computed(() => {
        let all = [];

        getCategories.value.forEach((category) => {
            category.move_options?.forEach((item) => {
                all.push(item);
            });
        });

        inventoryOptions.value = all.map((item) => {
            return {
                id: item.id,
                volume: item.volume,
            };
        });

        let totalSpace = 0;

        if (selectedItems.value && selectedItems.value.length > 0) {
            selectedItems.value.forEach((item) => {
                inventoryOptions.value.forEach((option) => {
                    if (item.inventory_option.id == option?.id && item.qty) {
                        totalSpace += option.volume * item.qty;
                    }
                });
            });

            return totalSpace.toFixed(2);
        } else {
            return 0;
        }
    });

    const getCountByCategoryId = computed(() => {
        return (categoryId, fields) => {
            // Return 0 if there are no values
            if (!fields || fields.length === 0) {
                return 0;
            }

            // Find options for the given categoryId
            let categoryOptions = [];
            for (let category of categories.value) {
                if (category.id === categoryId) {
                    categoryOptions = category.move_options;
                    break;
                }
            }

            // If no options found for the category, return 0
            if (!categoryOptions.length) {
                return 0;
            }

            // Filter values that have their inventory_option_id in the categoryOptions
            return fields
                .filter((field) => categoryOptions.some((option) => option.id === field.value.inventory_option_id))
                .reduce((total, field) => total + field.value.qty, 0);
        };
    });

    const getItemCountById = computed(() => {
        return (itemId) => {
            if (selectedItems.value && selectedItems.value.length > 0) {
                return (
                    selectedItems.value.find((inventoryItem) => {
                        return inventoryItem.inventory_option.id === itemId;
                    })?.qty || 0
                );
            } else {
                return 0;
            }
        };
    });

    const hasHeavyItem = computed(() => {
        if (selectedItems.value && selectedItems.value.length) {
            return selectedItems.value.some((item) => item.inventory_option.is_heavy && item.qty);
        }
        return false;
    });

    const getSelectedQtyForOption = computed(() => {
        return (optionId) => {
            if (selectedItems.value && selectedItems.value.length > 0) {
                return (
                    selectedItems.value.find((inventoryItem) => {
                        return inventoryItem.inventory_option.id === optionId;
                    })?.qty || 0
                );
            } else {
                return 0;
            }
        };
    });

    const getSelectedForForm = computed(() => {
        return selectedItems.value.map((item) => {
            return {
                inventory_option_id: item.inventory_option.id,
                qty: item.qty,
            };
        });
    });

    /*
     *  Return
     */
    return {
        init,
        loading,
        categories,
        selectedItems,
        filterCategory,
        searchTerm,
        searched,
        isFetchingSearch,
        showPotPlantAlert,

        // Custom Options
        toggleCustomItemForm,
        retrieveCustomOptionItems,
        createCustomOptionItem,
        addCustomOption,

        retrieve,
        update,

        setCategoryFilter,
        getCategories,
        getOptions,
        getCalculatedSpace,
        getCountByCategoryId,
        getItemCountById,
        hasHeavyItem,
        getSelectedForForm,
        getSelectedQtyForOption,

        // hack to get around push not working from custom item form
        push,
    };
});
