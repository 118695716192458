<template>
    <component
        :is="getComponent()"
        :id="name"
        :tabindex="tabindex"
        class="group relative flex w-fit flex-shrink-0 cursor-pointer appearance-none items-center justify-center rounded-full"
        :type="type"
        :href="href"
        :form="form"
        :to="to"
        :target="getTarget()"
        :class="[getStyles(), getSize(), { '!cursor-wait': loading, '!cursor-not-allowed': disabled }]"
        :disabled="disabled || loading"
    >
        <div
            class="flex items-center gap-1.5"
            :class="{
                'flex-row-reverse': iconRight,
                invisible: loading,
            }"
        >
            <SvgIcon
                v-if="icon"
                :name="icon"
                class="mx-auto h-5 w-5 overflow-visible"
                :class="{
                    'transition-all duration-75': iconAnimate && !disabled,
                    'group-hover:translate-x-1': iconAnimate && !disabled && iconRight,
                    'group-hover:-translate-x-1': iconAnimate && !disabled && !iconRight,
                }"
            />
            <span class="whitespace-nowrap empty:hidden"><slot /></span>
        </div>
        <div
            v-if="loading"
            class="absolute inset-0 flex h-full w-full"
        >
            <svg
                class="m-auto h-5 w-5 animate-spin"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.6567 12C23.3986 12 24.0078 11.3967 23.9249 10.6594C23.6671 8.36633 22.752 6.18569 21.2761 4.38728C19.5149 2.2412 17.064 0.772199 14.3411 0.230577C11.6182 -0.311045 8.79169 0.108223 6.34324 1.41694C3.89479 2.72567 1.97588 4.84287 0.913446 7.4078C-0.148984 9.97273 -0.289191 12.8267 0.516716 15.4834C1.32262 18.1401 3.02478 20.4352 5.33316 21.9776C7.64153 23.52 10.4133 24.2143 13.1762 23.9422C15.4915 23.7142 17.6805 22.8193 19.4843 21.3801C20.0642 20.9174 20.06 20.06 19.5354 19.5354V19.5354C19.0108 19.0108 18.1651 19.02 17.5705 19.4638C16.218 20.4732 14.6091 21.1014 12.9129 21.2685C10.7685 21.4797 8.61734 20.9409 6.82578 19.7438C5.03422 18.5467 3.71315 16.7654 3.08768 14.7035C2.4622 12.6416 2.57102 10.4266 3.39558 8.43593C4.22015 6.44526 5.70945 4.80207 7.60972 3.78636C9.50999 2.77064 11.7037 2.44524 13.8169 2.8656C15.9302 3.28596 17.8324 4.42607 19.1993 6.09167C20.2806 7.40924 20.9741 8.99114 21.2167 10.6613C21.3233 11.3955 21.9148 12 22.6567 12V12Z"
                    fill="currentColor"
                />
            </svg>
        </div>
    </component>
</template>
<script setup>
import SvgIcon from '@/components/utilities/SvgIcon.vue';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    primary: {
        type: Boolean,
        required: false,
        default: false,
    },
    secondary: {
        type: Boolean,
        required: false,
        default: false,
    },
    tertiary: {
        type: Boolean,
        required: false,
        default: false,
    },
    quaternary: {
        type: Boolean,
        required: false,
        default: false,
    },
    alternate: {
        type: Boolean,
        required: false,
        default: false,
    },
    success: {
        type: Boolean,
        required: false,
        defualt: false,
    },
    link: {
        type: Boolean,
        required: false,
        default: false,
    },
    small: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    form: {
        type: [String, null],
        required: false,
        default: null,
    },
    iconRight: {
        type: Boolean,
        required: false,
        default: false,
    },
    borderless: {
        type: Boolean,
        required: false,
        default: false,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    icon: {
        type: [String, null],
        required: false,
        default: null,
    },
    iconAnimate: {
        type: Boolean,
        required: false,
        default: false,
    },
    href: {
        type: [String, null],
        required: false,
        default: null,
    },
    to: {
        type: [String, null],
        required: false,
        default: null,
    },
    target: {
        type: [String, null],
        required: false,
        default: null,
    },
    type: {
        type: String,
        required: false,
        default: 'submit',
    },
    tabindex: {
        type: [String, Number],
        required: false,
        default: 0,
    },
});

function getStyles() {
    let styles = '';
    if (props.disabled) {
        styles = 'bg-muval-gray-5 text-muval-gray-3 cursor-not-allowed';
    } else if (props.link) {
        if (!props.alternate) {
            styles = 'text-muval-sky-1 hover:text-muval-blue-1 hover:underline active:text-muval-blue-1';
        } else {
            styles = 'text-brand hover:text-brand-shade hover:underline';
        }
    } else if (props.success) {
        styles = 'border border-muval-success text-muval-success hover:text-muval-success';
    } else if (props.primary) {
        if (!props.alternate) {
            styles = 'bg-muval-sky-1 text-white hover:bg-muval-blue-1';
        } else {
            styles = 'bg-brand text-white hover:bg-brand-shade focus:ring-2 focus:ring-offset-2 focus:ring-brand-shade';
        }
    } else if (props.secondary) {
        if (!props.alternate) {
            styles = 'border border-muval-sky-1 text-muval-sky-1 hover:border-muval-blue-1 hover:text-muval-blue-1';
        } else {
            styles = 'border border-brand text-brand hover:border-brand-shade hover:text-brand-shade';
        }
    } else if (props.tertiary) {
        if (!props.alternate) {
            styles = 'border border-muval-gray-4 text-muval-gray-1 hover:text-muval-gray-2 font-medium';
        } else {
            styles = 'border border-muval-gray-4 text-muval-gray-1 hover:text-muval-gray-2 font-medium';
        }
    } else if (props.quaternary) {
        if (!props.alternate) {
            styles = 'text-muval-gray-1 hover:bg-muval-gray-5 hover:text-muval-gray-1';
        } else {
            styles = 'text-brand hover:bg-muval-gray-5 hover:text-brand-shade';
        }
    } else {
        if (!props.alternate) {
            styles = 'bg-muval-brand text-white hover:bg-muval-blue-1';
        } else {
            styles = 'bg-brand text-white hover:bg-brand-shade focus:ring-2 focus:ring-offset-2 focus:ring-brand-shade';
        }
    }

    if (props.borderless) {
        styles += ' !border-none !focus:ring-2 !focus:ring-offset-2 !focus:ring-brand-shade hover:underline';
    }

    return styles;
}

function getSize() {
    if (props.link) {
        return 'text-sm';
    } else if (props.small) {
        return 'text-xs h-input-button-small px-3';
    } else {
        return 'text-sm h-input-button-mobile sm:h-input-button px-4';
    }
}

function getComponent() {
    if (props.href) {
        return 'a';
    } else if (props.to) {
        return 'router-link';
    } else {
        return 'button';
    }
}

function getTarget() {
    if (props.target && props.href) {
        return '_blank';
    }

    return '';
}
</script>
