import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { useSessionStore, useAppStore } from '@/store';
import { openConfirmDialog } from '@/composables/dialog';

export const useInsuranceStore = defineStore('insurance', () => {
    const app = useAppStore();
    const sessionStore = useSessionStore();
    const loading = ref(false);
    const categoryUrl = ref('/customers/book/inventory-categories');
    const serverCategories = ref(null);
    const calculation = ref(null);

    const getFiles = computed(() => {
        return app.predefined.insurance_files;
    });

    async function init() {
        loading.value = true;

        await fetchCategories();

        loading.value = false;
    }

    async function precognitionPay() {
        try {
            await window.axios.get(
                `/customers/book/orders/${sessionStore.getOrderId}/insurance/${sessionStore.getInsuranceId}/precognition/pay`,
            );
        } catch (e) {
            console.log('Store@Insurance precognition() error', e);

            throw e;
        }
    }

    async function calculate(inventory, excess) {
        try {
            const resp = await window.axios.post(
                `/customers/book/orders/${sessionStore.getOrderId}/insurance/calculate`,
                {
                    excess,
                    inventory,
                },
            );

            calculation.value = resp.data.data;
        } catch (e) {
            console.log('Store@Insurance calculate() error', e);

            throw e;
        }
    }

    async function fetchCategories() {
        try {
            loading.value = true;

            const response = await window.axios.get(`${categoryUrl.value}?type=INSURANCE`);

            serverCategories.value = cloneDeep(response.data.data);

            loading.value = false;
        } catch (error) {
            console.log('Store@Insurance Fetch categories error', error);

            throw error;
        }
    }

    async function createInsurance(values) {
        try {
            const resp = await window.axios.post(`/customers/book/orders/${sessionStore.getOrderId}/insurance`, {
                ...values,
            });

            if (resp.data.insurance) {
                await sessionStore.setInsurance(resp.data.insurance);

                await sessionStore.retrieve();

                return resp.data.insurance;
            }
        } catch (e) {
            console.log('Store@Insurance createInsurance() error', e);
            throw e;
        }
    }

    async function updateInsurance(values) {
        try {
            const resp = await window.axios.patch(`/customers/book/orders/${sessionStore.getOrderId}/insurance`, {
                ...values,
            });

            if (resp.data.insurance) {
                await sessionStore.setInsurance(resp.data.insurance);

                await sessionStore.retrieve();

                return resp.data.insurance;
            }
        } catch (e) {
            console.log('Store@Insurance updateInsurance() error', e);
            throw e;
        }
    }

    const isRemoving = ref(false);

    async function removeInsurance() {
        let outcome = false;

        try {
            isRemoving.value = true;

            const confirmDialog = await openConfirmDialog({
                title: 'Are you sure?',
                description: `By removing ${sessionStore.getInsurancePolicyBrand}, you will lose all the details you have entered. Continue to remove?`,
                confirm: 'Yes, remove',
                cancel: 'Cancel',
            });

            if (confirmDialog == 'confirm') {
                await window.axios.delete(
                    `/customers/book/orders/${sessionStore.getOrderId}/insurance/${sessionStore.getInsuranceItemProduct.id}`,
                );

                await sessionStore.setInsurance(null);

                await sessionStore.retrieve();

                outcome = true;
            }
        } catch (error) {
            console.log('Error deleting insurance', error);
        } finally {
            isRemoving.value = false;
        }

        return outcome;
    }

    const isEligible = computed(() => {
        return !!(calculation.value && calculation.value.eligible);
    });

    const getInsuredInventoryValue = computed(() => {
        return calculation.value?.insured_inventory_value.formatted ?? '$0';
    });

    const getTotalCoverAmount = computed(() => {
        return calculation.value?.total_cover.formatted ?? '$0';
    });

    const getExcess = computed(() => {
        return calculation.value?.excess.formatted ?? '$0';
    });

    const getPremium = computed(() => {
        return calculation.value?.premium.formatted ?? '$0';
    });

    const getMuvexpressCover = computed(() => {
        return calculation.value?.muvexpress_cover.formatted ?? '$0';
    });

    const getMuvexpressGapAmount = computed(() => {
        return calculation.value?.muvexpress_gap.formatted ?? '$0';
    });

    const canRemove = computed(() => {
        if (sessionStore.getInsuranceItem?.status === 'CONFIRMED' || sessionStore.isLocked) {
            return false;
        }

        return true;
    });

    const isConfirmed = computed(() => sessionStore.getInsuranceItem?.status === 'CONFIRMED');

    const isQuoted = computed(() => {
        if (sessionStore.getInsuranceItemProduct.status === 'QUOTED') {
            return true;
        }

        return false;
    });

    const getPayButtonCopy = computed(() => {
        if (sessionStore.hasConfirmed) {
            if (sessionStore.isRelocation) {
                return 'Request approval';
            }

            return 'Pay';
        }

        return 'Update';
    });

    const getPaidButtonCopy = computed(() => {
        if (isConfirmed.value && ['READY', 'COMPLETED'].includes(sessionStore.getInsuranceItemProduct.status)) {
            return 'Paid';
        }

        if (isConfirmed.value) {
            return 'Pending';
        }

        return null;
    });

    const hasFailedConfirmationPayment = computed(() => {
        if (sessionStore.getInsuranceItemProduct.confirmation_payment?.status == 'FAILED') {
            return true;
        }

        return false;
    });

    return {
        loading,
        categoryUrl,
        serverCategories,
        calculation,
        getFiles,
        init,
        precognitionPay,
        calculate,
        fetchCategories,
        createInsurance,
        updateInsurance,
        removeInsurance,

        // Getters
        isEligible,
        getInsuredInventoryValue,
        getTotalCoverAmount,
        getExcess,
        getPremium,
        getMuvexpressCover,
        getMuvexpressGapAmount,
        canRemove,
        isConfirmed,
        isRemoving,
        isQuoted,
        getPayButtonCopy,
        getPaidButtonCopy,
        hasFailedConfirmationPayment,
    };
});
