export default [
    /*
     * At the point the router enters step routes,
     * the session store and any async data should have been fetched.
     *
     * So beforeEnter guards here can and should be synchronous.
     */

    /*
     * Move
     */
    // Pickup
    {
        path: 'pickup_details/pickup_address',
        name: 'PickupAddress',
        meta: {
            title: 'Pickup Address',
            requireSession: false,
        },
        component: () => import('@/steps/PickupAddress.vue'),
    },
    {
        path: 'pickup_details/pickup_dwelling',
        name: 'PickupDwelling',
        meta: {
            title: 'Pickup Dwelling',
        },
        component: () => import('@/steps/PickupDwelling.vue'),
    },
    {
        path: 'pickup_details/pickup_access',
        name: 'PickupAccess',
        meta: {
            title: 'Pickup Access',
        },
        component: () => import('@/steps/PickupAccess.vue'),
    },
    {
        path: 'pickup_details/pickup_space',
        name: 'PickupSpace',
        meta: {
            title: 'Pickup Space',
        },
        component: () => import('@/steps/PickupSpace.vue'),
    },

    // Delivery
    {
        path: 'delivery_details/delivery_address',
        name: 'DeliveryAddress',
        meta: {
            title: 'Delivery Address',
        },
        component: () => import('@/steps/DeliveryAddress.vue'),
    },
    {
        path: 'delivery_details/delivery_access',
        name: 'DeliveryAccess',
        meta: {
            title: 'Delivery Access',
        },
        component: () => import('@/steps/DeliveryAccess.vue'),
    },

    // Dates
    {
        path: 'move_date',
        name: 'MoveDates',
        meta: {
            title: 'Move Dates',
        },
        component: () => import('@/steps/MoveDate.vue'),
    },

    // Utilities
    {
        path: 'utilities',
        name: 'Utilities',
        meta: {
            title: 'Utility',
        },
        component: () => import('@/steps/Utilities.vue'),
    },

    // Inventory
    {
        path: 'inventory',
        name: 'Inventory',
        meta: {
            title: 'Inventory',
        },
        component: () => import('@/steps/Inventory.vue'),
    },

    // Insurance
    {
        path: 'insurance',
        name: 'Insurance',
        meta: {
            title: 'Insurance',
        },
        component: () => import('@/steps/Insurance.vue'),
    },

    // Customer
    {
        path: 'customer_details',
        name: 'CustomerDetails',
        meta: {
            title: 'Customer Details',
        },
        component: () => import('@/steps/CustomerDetails.vue'),
    },
];
