<template>
    <div data-testid="form-summary">
        <template v-if="!session.loading">
            <div>
                <span class="text-base font-medium">
                    Pickup {{ session.getPrimaryProduct.delivery_address ? '& Delivery' : '' }}
                </span>
            </div>

            <div>
                <ul
                    role="list"
                    class="mt-3 space-y-4"
                >
                    <li
                        v-for="(item, itemIdx) in meta"
                        :key="item.id"
                        class="relative flex gap-x-2"
                    >
                        <div
                            :class="[
                                itemIdx === meta.length - 1 ? 'h-6' : '-bottom-10',
                                'absolute  left-0 top-0 flex w-6 justify-center',
                            ]"
                        >
                            <div class="relative flex h-full w-full">
                                <svg
                                    class="absolute left-1/2 h-full w-px -translate-x-1/2"
                                    preserveAspectRatio="none"
                                    width="0.5"
                                    height="400"
                                    viewBox="0 0 1 400"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.499798 0L0.499995 400"
                                        stroke="#8F8F8F"
                                        stroke-dasharray="2 2"
                                        vector-effect="non-scaling-stroke"
                                    />
                                </svg>
                            </div>
                        </div>

                        <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                            <div
                                v-if="item.type === 'pickup'"
                                class="h-[7px] w-[7px] rounded-full bg-black"
                            ></div>
                            <div
                                v-else
                                class="h-[7px] w-[7px] rounded-full border border-black"
                            ></div>
                        </div>
                        <div class="flex w-full flex-col gap-3">
                            <div class="flex flex-col gap-[5px]">
                                <div class="flex justify-between py-0.5 text-xs leading-5 text-muval-gray-2">
                                    <span
                                        :data-testid="`form-summary-${item.type}-address`"
                                        class="text-sm font-medium capitalize text-muval-gray-1"
                                    >
                                        {{ item.address.value }}
                                    </span>
                                </div>

                                <div
                                    v-if="
                                        item.dwelling_type?.value ||
                                        item.dwelling_bedrooms?.value ||
                                        item.dwelling_area?.value
                                    "
                                    class="flex gap-1 text-sm text-muval-gray-1"
                                >
                                    <template v-if="item.dwelling_type?.value">
                                        <span :data-testid="`form-summary-${item.type}-dwelling-type`">
                                            {{ $filters.capitalize(item.dwelling_type?.value) }}
                                        </span>
                                    </template>
                                    <template v-if="item.dwelling_bedrooms?.value != undefined">
                                        <span class="text-[#AFAFAF]">•</span>
                                        <span data-testid="form-summary-pickup-bedroom-count">
                                            {{
                                                item.dwelling_bedrooms?.value == 0 ?
                                                    'Studio'
                                                :   item.dwelling_bedrooms?.value + ' Bedroom'
                                            }}
                                        </span>
                                    </template>
                                    <template v-if="item.dwelling_area?.value">
                                        <span class="text-[#AFAFAF]">•</span>
                                        {{ item.dwelling_area?.value }}m&sup2;
                                    </template>
                                </div>
                            </div>
                            <div
                                v-if="item.access?.value?.length > 0"
                                class="flex flex-wrap gap-1"
                            >
                                <span
                                    v-for="(accessItem, index) in item.access?.value"
                                    :key="index"
                                    :data-testid="`form-summary-${item.type}-access-tag-${accessItem.accessTag.item}`"
                                    class="flex w-auto whitespace-nowrap rounded-md bg-muval-gray-5 px-2 py-0.5 text-sm font-medium"
                                >
                                    {{ accessItem.accessTag.item }}
                                    {{
                                        accessItem.value && accessItem.accessTag.unit ?
                                            ` - ${accessItem.value} ${accessItem.accessTag.unit}`
                                        :   null
                                    }}
                                </span>
                            </div>

                            <!-- Edit details button -->
                            <EditSummaryButton
                                v-if="!(session.hasConfirmed || session.isLocked)"
                                @click="openDialog(item.type)"
                            >
                                Edit details
                            </EditSummaryButton>
                        </div>
                    </li>
                </ul>

                <template v-if="pickupDate">
                    <hr class="my-3" />
                    <div class="flex flex-col gap-2">
                        <div class="flex items-center justify-between font-medium">
                            Pickup date
                            <div
                                data-testid="form-summary-pickup-date"
                                class="flex items-center gap-1 text-sm"
                            >
                                {{ pickupDate }}
                                <template
                                    v-if="
                                        session.getPickupDateFlexibility &&
                                        session.getPickupDateFlexibility !== 'EXACT' &&
                                        !session.hasConfirmed
                                    "
                                >
                                    <span class="text-muval-gray-3">
                                        ({{ session.getPickupDateFlexibilityFormatted }})
                                    </span>
                                </template>
                            </div>
                        </div>

                        <!-- Edit date button -->
                        <EditSummaryButton
                            v-if="!(session.hasConfirmed || session.isLocked)"
                            @click="openDateDialog"
                        >
                            Edit date
                        </EditSummaryButton>
                    </div>
                </template>

                <template v-if="session.getQuoteType && !(session.isLocal && session.hasConfirmed)">
                    <hr class="my-4" />
                    <div class="flex flex-col gap-2">
                        <div class="flex items-center justify-between font-medium">
                            <span data-testid="form-summary-title-inventory">Inventory</span>
                            <span class="text-sm">
                                <div class="flex flex-col">
                                    <span class="ml-auto flex items-center gap-2">
                                        <div>
                                            <template
                                                v-if="
                                                    session.getQuoteType != 'ACCURATE_QUOTE' &&
                                                    session.getQuoteType != 'QUICK_QUOTE' &&
                                                    !session.hasPendingInventory
                                                "
                                            >
                                                {{ session.getSpace }} m&sup3;
                                            </template>

                                            <template v-if="session.getQuoteType == 'ACCURATE_QUOTE'">
                                                <span>{{ session.getCurrentInventoryQty }} items</span>
                                            </template>

                                            <template v-if="session.getQuoteType == 'SPACE_KNOWN'">
                                                <span class="text-muval-gray-3">(Space requested)</span>
                                            </template>
                                        </div>
                                    </span>
                                </div>
                            </span>
                        </div>

                        <div
                            v-if="session.hasPendingInventory"
                            class="flex flex-col rounded-md bg-gray-50 p-2 px-4"
                        >
                            <div class="flex items-center justify-between">
                                <span class="font-medium text-amber-500">Pending approval</span>

                                <span class="text-sm text-muval-gray-3">
                                    {{ session.getPendingInventorySpace }} m
                                    <sup>3</sup>
                                    ({{ session.getPendingInventoryCount }} items)
                                </span>
                            </div>
                            Since your move is already pending, a Muval agent will review your inventory.
                        </div>

                        <div
                            v-if="session.isLocked"
                            class="flex flex-col rounded-md bg-gray-50 p-2 text-sm"
                        >
                            <div class="flex items-center justify-between">
                                <span class="text-amber-500">Locked</span>
                            </div>
                            Your move has been locked, please contact Muval for any changes to your inventory.
                        </div>

                        <div
                            v-if="!session.isCompleted && session.hasMoveBeenAccepted && session.isJobWithinTwoDays"
                            class="rounded-lg bg-app p-2 text-sm"
                        >
                            Your move is within 2 days, please contact the removalist for any changes to your inventory.
                        </div>

                        <template v-if="session.getQuoteType === 'ACCURATE_QUOTE'">
                            <EditSummaryButton @click="openSpaceDialog">Edit inventory</EditSummaryButton>
                        </template>

                        <template v-if="session.getQuoteType === 'SPACE_KNOWN' && !session.isLocked">
                            <EditSummaryButton @click="openSpaceDialog">Edit details</EditSummaryButton>
                        </template>

                        <div
                            v-if="session.getQuoteType == 'QUICK_QUOTE'"
                            class="flex flex-col"
                        >
                            <!-- Local Inventory Add CTA -->
                            <template v-if="session.isLocal">
                                <div class="rounded-lg bg-app p-2 text-sm">
                                    An accurate inventory will help us ensure you get the correctly sized truck for your
                                    move day.
                                </div>

                                <!-- Edit date button -->
                                <EditSummaryButton
                                    v-if="!session.hasConfirmed && !session.isLocked"
                                    @click="openSpaceDialog"
                                >
                                    Add inventory
                                </EditSummaryButton>
                            </template>

                            <!-- Interstate Inventory Add CTA -->
                            <template v-else-if="!session.hasPendingInventory">
                                <div class="flex flex-col gap-1.5 rounded-lg bg-muval-gray-6 p-3">
                                    <span class="text-sm font-medium text-black">
                                        Improve your quote accuracy by up to 25%
                                    </span>
                                    <span class="text-xs text-black/60">
                                        Add your detailed inventory to help us ensure you get
                                        <template v-if="session.isSelfPack">the correct containers</template>
                                        <template v-else>the correctly sized truck</template>
                                        for your move day.
                                    </span>
                                    <EditSummaryButton
                                        v-if="!session.isLocked"
                                        @click="openSpaceDialog"
                                    >
                                        Add a detailed inventory
                                    </EditSummaryButton>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>

                <div
                    v-if="discount.hasCoupon && !session.hasConfirmed && notReviewPage"
                    class="mt-2 border-t pt-3 text-center text-xs"
                >
                    Your
                    <span class="font-bold text-brand">#{{ discount.getCouponCode }}</span>
                    discount will be applied at checkout.
                </div>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col gap-4">
                <div
                    v-for="index in 5"
                    :key="index"
                    class="h-5 w-full animate-pulse rounded-md bg-muval-gray-5"
                ></div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { useSessionStore } from '@/store';
import { openDateDialog, openSpaceDialog, openPickupDialog, openDeliveryDialog } from '@/composables/dialog';
import { useDiscountStore } from '@/store';

defineProps({
    showTitle: {
        type: Boolean,
        default: true,
    },
});

const router = useRouter();
const notReviewPage = computed(() => {
    return !router.currentRoute.value.path.includes('/review');
});

const discount = useDiscountStore();
const openDialog = (type) => {
    if (type === 'pickup') {
        openPickupDialog();
    } else {
        openDeliveryDialog();
    }
};

const session = useSessionStore();

const pickupMeta = reactive({
    id: 1,
    type: 'pickup',
    hidden: computed(() => {
        // Should collapse when not in the /pickup_details/... router
        if (router.currentRoute.value.path.includes('/results/')) {
            return true;
        }
        return false;
    }),
    address: {
        value: computed(() => session.getPrimaryProduct.pickup_address),
    },
    dwelling_type: {
        value: computed(() => session.getPickupDwelling),
    },
    dwelling_bedrooms: {
        value: computed(() => session.getPickupBedrooms),
    },
    dwelling_area: {
        value: computed(() => session.getPickupArea),
    },
    access: {
        show: true,
        value: computed(() => session.getPickupAccess),
    },
});

const deliveryMeta = reactive({
    id: 2,
    type: 'delivery',
    hidden: computed(() => {
        // Should collapse when not in the /pickup_details/... router
        if (router.currentRoute.value.path.includes('/results/')) {
            return true;
        }
        return false;
    }),
    address: {
        value: computed(() => session.getPrimaryProduct.delivery_address),
    },
    dwelling_type: {
        value: computed(() => session.getDeliveryDwelling),
    },
    dwelling_bedrooms: {
        value: computed(() => session.getDeliveryBedrooms),
    },
    dwelling_area: {
        value: computed(() => session.getDeliveryArea),
    },
    access: {
        show: true,
        value: computed(() => session.getDeliveryAccess),
    },
});

const meta = ref([pickupMeta]);

// Watch the delivery meta for changes to update meta
watch(
    () => deliveryMeta.address.value,
    (newAddress) => {
        if (newAddress && newAddress.length > 0) {
            if (!meta.value.includes(deliveryMeta)) {
                meta.value.push(deliveryMeta);
            }
        } else {
            const index = meta.value.indexOf(deliveryMeta);
            if (index !== -1) {
                meta.value.splice(index, 1);
            }
        }
    },
    { immediate: true },
);

const pickupDate = computed(() => session.getPrimaryProduct.effective_pickup_date_formatted);
</script>
