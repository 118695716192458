<template>
    <div
        :class="{
            'w-full sm:max-w-[477px]': withinModal,
        }"
    >
        <div>
            <BankDepositModal
                :order-item-id="props.orderItemId"
                :order-payment-id="props.orderPaymentId"
                :payment-amount="currentPaymentAmount"
                :payment-label="paymentLabel"
                @payment:success="handlePaymentSuccess"
            />
            <form
                v-if="!success"
                id="payment-form"
                @submit="handleSubmit"
            >
                <div class="flex flex-col gap-5">
                    <div class="text-sm text-muval-gray-1">
                        <slot
                            name="info"
                            v-bind="{ payment: orderPayment }"
                        ></slot>
                    </div>
                    <div
                        id="payment-element"
                        class="min-h-[150px]"
                        :class="{
                            ' max-w-[477px]': withinModal,
                        }"
                    >
                        <!-- Mount the Payment Element here -->
                    </div>
                    <div class="items-top my-2 flex gap-2">
                        <input
                            id="terms"
                            v-model="termsBox"
                            type="checkbox"
                            class="mt-1 cursor-pointer rounded-muval-1 border-muval-gray-3 text-black focus:outline-none focus:ring-0"
                        />
                        <label
                            for="terms"
                            class="text-xs text-muval-gray-2"
                        >
                            By proceeding you confirm that you have read and agree to Muval's
                            <a
                                href="https://www.muval.com.au/privacy-policy"
                                class="link text-muval-gray-2"
                                target="_blank"
                                >Privacy Policy</a
                            >
                            and
                            <a
                                href="https://www.muval.com.au/customer-terms-conditions"
                                class="link text-muval-gray-2"
                                target="_blank"
                                >Terms and Conditions</a
                            >
                            <template v-if="session.isSelectedMatchWithinFiveDays">
                                including the cancellation terms relating to short notice bookings
                            </template>
                            and authorise Muval to charge your card on behalf of the service provider(s) in accordance
                            with the payment schedule.
                        </label>
                    </div>
                    <div class="flex flex-row items-center justify-between gap-4">
                        <div class="flex flex-col">
                            <span class="text-sm font-medium leading-4">
                                <span class="text-[#393939]">{{ currentPaymentAmount }}</span>
                                <span class="pl-1 text-[#9B9B9B]">{{ paymentLabel }}</span>
                            </span>
                            <span class="text-xxs leading-4 text-[#9B9B9B]">
                                <Tippy
                                    v-if="session.cardFeesEnabled"
                                    trigger="mouseenter click"
                                    animation="scale"
                                    placement="auto"
                                >
                                    <span
                                        class="block cursor-pointer italic underline decoration-dotted decoration-0 sm:inline"
                                        >Card fees may apply.</span
                                    >
                                    <template #content>
                                        <div class="rounded-muval-2 bg-white p-4 text-xs shadow-muval-2">
                                            Depending on the card used, processing fees will apply to your order:
                                            {{ app.paymentSettings.debit_card_fee }} for debit cards,
                                            {{ app.paymentSettings.credit_card_fee }} for Visa and MasterCard credit
                                            cards,
                                            {{ app.paymentSettings.amex_credit_card_fee }}
                                            American Express credit cards.
                                        </div>
                                    </template>
                                </Tippy>
                                Alternatively,
                                <a
                                    class="link text-[#9B9B9B]"
                                    @click.prevent="handleBank"
                                >
                                    pay by bank deposit</a
                                >.
                            </span>
                        </div>
                        <MuvalButton
                            id="submit"
                            :loading="isLoading"
                            primary
                            alternate
                            :disabled="!termsBox"
                        >
                            <div
                                id="spinner"
                                class="spinner hidden"
                            ></div>
                            <span id="button-text">{{ confirmText }}</span>
                        </MuvalButton>
                    </div>
                    <MuvalBanner
                        v-if="message"
                        type="warning"
                        icon="system-alert"
                        inline
                    >
                        {{ message }}
                    </MuvalBanner>
                </div>

                <div v-if="error">Error</div>
            </form>
            <div v-if="success"></div>
        </div>
    </div>
</template>

<script setup>
import { loadStripe } from '@stripe/stripe-js';
import { useAppStore, useSessionStore, usePaymentStore } from '@/store';
import MuvalButton from '@/components/button/MuvalButton.vue';

const app = useAppStore();
const session = useSessionStore();
const payment = usePaymentStore();
const props = defineProps({
    serviceType: {
        type: String,
        default: null,
    },
    orderPaymentId: {
        type: [String, Number, null],
        default: null,
    },
    orderItemId: {
        type: [String, Number, null],
        required: false,
        default: null,
    },
    confirmText: {
        type: String,
        default: 'Send booking request',
    },
    paymentAmount: {
        type: String,
        default: null,
        required: false,
    },
    paymentLabel: {
        type: String,
        required: false,
        default: '',
    },
    withinModal: {
        type: Boolean,
        required: false,
        default: true,
    },
});

const termsBox = ref(false);

const emit = defineEmits(['payment:success', 'payment:error', 'payment:intent']);

function addScripts() {
    const userMeta = document.createElement('meta');
    userMeta.setAttribute('name', 'viewport');
    userMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, user-scalable=no');
    document.head.appendChild(userMeta);
}

const appearance = {
    theme: 'flat',
    variables: {
        fontFamily: ' "Roboto", sans-serif',
        fontLineHeight: '1.5',
        borderRadius: '4px',
        colorBackground: '#F6F8FA',
        colorPrimaryText: '#262626',
        colorTextPlaceholder: '#999999',
    },
    rules: {
        '.Block': {
            backgroundColor: 'var(--colorBackground)',
            boxShadow: 'none',
            padding: '12px',
        },
        '.Input': {
            paddingTop: '8px',
            paddingRight: '14px',
            paddingBottom: '8px',
            paddingLeft: '14px',
            fontSize: '14px',
            lineHeight: '30px',
            boxShadow: 'none',
            borderWidth: '1px',
            borderColor: '#d0d0d0',
            borderStyle: 'solid',
        },
        '.Input:hover': {
            boxShadow: 'none',
        },
        '.Input:focus': {
            borderColor: '#000000',
            boxShadow: 'none',
        },
        '.Input:disabled, .Input--invalid:disabled': {
            color: 'lightgray',
        },
        '.Tab': {
            padding: '10px 12px 8px 12px',
            border: 'none',
        },
        '.Tab:hover': {
            border: 'none',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
        },
        '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
            border: 'none',
            backgroundColor: '#fff',
            boxShadow:
                '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
        },
        '.Label': {
            fontWeight: '500',
            fontSize: '14px',
            paddingBottom: '4px',
            marginTop: '8px',
        },
        '.Error': {
            fontSize: '12px',
            marginTop: '8px',
        },
    },
    terms: {
        card: 'never',
    },
};
const success = ref(false);
const paymentError = ref(false);
const message = ref(null);
const isLoading = ref(true);
const clientSecret = ref(null);

let stripe;
let elements;

const orderPayment = ref(null);

const currentPaymentAmount = computed(() => {
    if (orderPayment.value) {
        return orderPayment.value.amount.formatted;
    } else {
        return props.paymentAmount;
    }
});

onMounted(async () => {
    emit('payment:intent');

    addScripts();

    stripe = await loadStripe(`${import.meta.env.VITE_STRIPE_KEY}`);

    await initElements();

    isLoading.value = false;
});

const initElements = async () => {
    const { payment: paymentResponse, clientSecret: clientSecretResponse } = await payment.retrievePaymentSecret(
        props.orderPaymentId,
    );

    clientSecret.value = clientSecretResponse;
    orderPayment.value = paymentResponse;

    elements = stripe.elements({
        clientSecret: clientSecret.value,
        appearance: appearance,
    });

    elements.update({ locale: 'au' });

    const paymentElement = elements.create('payment', {
        terms: { auBecsDebit: 'never', card: 'never' },
    });

    paymentElement.mount('#payment-element');
};

const handleBank = async () => {
    try {
        app.openBankDepositModal();
    } catch (error) {
        console.log('error', error);
    }
};

const handlePaymentSuccess = () => {
    emit('payment:success');
};

const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading.value) {
        return;
    }

    if (!termsBox.value) {
        return;
    }

    isLoading.value = true;

    try {
        message.value = null;

        await confirmSetup();

        if (paymentError.value) {
            emit('payment:error');
        } else {
            handlePaymentSuccess();
        }
    } catch (error) {
        if (error.message) {
            message.value = error.message;
        } else {
            message.value = 'An unexpected error occurred.';
        }
    } finally {
        isLoading.value = false;
    }
};

async function confirmSetup() {
    const result = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
    });

    if (result.error) {
        message.value = null;
        paymentError.value = true;

        payment.cardDeclined();

        if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
            message.value = result.error.message;
        } else {
            message.value.push('An unexpected error occurred.', result.error);
        }
    }

    if (result.setupIntent) {
        if (props.orderPaymentId) {
            try {
                await payment.retrySetup(props.orderPaymentId, result.setupIntent);
            } catch (error) {
                console.log('Error retrying setup', error);

                if (typeof error === 'object') {
                    message.value = error.message;
                    paymentError.value = true;

                    initElements();
                }
            }
        } else {
            let confirmError = await session.confirmBooking(
                result.setupIntent,
                props.orderItemId ? [{ id: props.orderItemId }] : null,
            );

            if (typeof confirmError === 'object') {
                message.value = confirmError.message;
                paymentError.value = true;

                initElements();
            }
        }

        session.retrieve();
    }
}
</script>
